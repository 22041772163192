import { Typography } from '../components/photographie/Typography'
import { css } from '../../styled-system/css'
import { Checkbox } from '../components/photographie/order/Checkbox'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useOrderValue, useSetOrder } from '../atoms/order.atom'
import { Button } from '../components/photographie/Button'
import { PreClaim, useApi } from '../hooks/useApi'
import { Input } from '../components/photographie/Input'
import OrderResume from '../components/photographie/order/OrderResume'
import { packages } from '@dup/products'
import { scrollToTop } from '../utils/scrollToTop'

export const Summary = ({ back }: { back: () => void }) => {
  const setOrder = useSetOrder()
  const discountInput = useRef<HTMLInputElement>(null)
  const { useDiscountCode, claim, reserve } = useApi()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const order = useOrderValue()
  const pkg = packages.find((pk) => pk.id === order.packageId)

  if (!pkg) {
    return null
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrder((_prevV) => {
      return {
        ..._prevV,
        allowShareImages: e.target.checked,
      }
    })
  }

  const handleCheckout = async () => {
    setLoading(true)
    if (!order.event) {
      setLoading(false)
      return
    }

    await claim({
      eventId: order.event.id,
      type: pkg.label,
    } as PreClaim)

    const sessionURL = await reserve(order)

    if (!sessionURL || !sessionURL.session) {
      setLoading(false)
      return
    }

    window.location.href = sessionURL.session
  }

  const handleUseDiscountCode = async () => {
    if (
      discountInput.current === null ||
      discountInput.current.value.trim().length === 0
    ) {
      return
    }

    const code = await useDiscountCode(discountInput.current.value)

    if (!code.valid) {
      setOrder((prevV) => {
        return {
          ...prevV,
          discountCode: {
            label: '',
            value: null,
            amount: 0,
          },
        }
      })
      return
    }

    setOrder((prevV) => {
      return {
        ...prevV,
        discountCode: {
          label: code.name,
          value: discountInput.current!.value,
          amount: code.amount_off,
        },
      }
    })
  }

  useEffect(scrollToTop, [])

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        gap: '3.125rem',
        flexWrap: 'wrap',
        flexDir: 'column',
        textAlign: 'center',
        lgDown: {
          gap: 0,
        },
      })}
    >
      <div
        className={css({
          flex: 0.5,
          display: 'flex',
          flexDir: 'column',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'center',
          width: '70%',
          lgDown: {
            width: '90%',
          },
        })}
      >
        <OrderResume />

        <div
          className={css({
            mt: '1rem',
            display: 'flex',
            flexDir: 'column',
            gap: '0.5rem',
          })}
        >
          <Typography
            className={css({ color: 'fonts.darkBlue', fontWeight: '600' })}
            as="h6"
          >
            Utiliser un code de réduction
          </Typography>

          <div className={css({ display: 'flex', gap: '0.75rem' })}>
            <Input
              ref={discountInput}
              placeholder="Code de réduction"
              type="text"
            />
            <Button
              onClick={handleUseDiscountCode}
              variants={{ format: 'normal', visual: 'secondary' }}
            >
              Utiliser
            </Button>
          </div>
        </div>
      </div>

      <div
        className={css({
          display: 'flex',
          flexDir: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
          width: '30%',
        })}
      >
        <Typography className={css({ color: 'fonts.darkBlue' })} as="span">
          En acceptant, vous consentez à l&apos;utilisation et à la diffusion
          des photos prises lors de la séance photo pour des fins
          promotionnelles.{' '}
        </Typography>

        <Checkbox onChange={onChange}>
          Accepter{' '}
          <Typography
            className={css({ cursor: 'pointer', color: 'blue' })}
            onClick={() => setVisible((prevV) => !prevV)}
            id="not-clickable"
            as="span"
          >
            (?)
          </Typography>
        </Checkbox>

        {visible && (
          <div
            className={css({
              fontStyle: 'italic',
              fontSize: '0.875rem',
            })}
          >
            Je reconnais et consens par la présente à autoriser
            l&apos;utilisation, la publication et la diffusion des photographies
            prises lors de la séance photo à laquelle J&apos;ai participé. Je
            comprends que ces photographies peuvent être utilisées à des fins
            commerciales, promotionnelles, publicitaires ou éducatives,
            notamment sur des sites web, des réseaux sociaux, des supports
            imprimés ou tout autre média, dans le cadre de la promotion des
            produits ou services. J&apos;accorde cette autorisation sans aucune
            rémunération ou compensation financière présente ou future. Je
            certifie également que j&apos;ai le droit de donner cette
            autorisation et que je libère par la présente le photographe ou
            toute partie associée à la séance photo de toute réclamation ou
            responsabilité en relation avec l&apos;utilisation des
            photographies.
          </div>
        )}
      </div>

      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2.5rem',
        })}
      >
        <Button
          variants={{ format: 'normal', visual: 'secondary' }}
          className={css({
            width: '12.5rem',
          })}
          onClick={back}
        >
          Retour
        </Button>
        <Button
          variants={{ format: 'normal', visual: 'digit' }}
          className={css({
            width: '12.5rem',
          })}
          onClick={handleCheckout}
          disabled={loading}
        >
          Réservé la séance
        </Button>
      </div>
    </div>
  )
}
