import React, { useEffect } from 'react'
import { Order, useSetOrder } from '../atoms/order.atom'
import { Typography } from '../components/photographie/Typography'
import { css } from '../../styled-system/css'
import { useState } from 'react'
import { Button } from '../components/photographie/Button'
import { scrollToTop } from '../utils/scrollToTop'
import { Package } from '@dup/products'
import { useApi } from '../hooks/useApi'
import {
  CalendarMonthChangeEvent,
  FormEvent,
  MyCalendar,
  Nullable,
} from '../components/photographie/order/Calendar'
import { WorkingHours } from '../components/photographie/order/WorkingHours'
import { navigate } from 'gatsby'

const DateAndTimeSelection = ({
  next,
  back,
  pkg,
}: {
  next: () => void
  back: () => void
  pkg: Package
}) => {
  const setOrder = useSetOrder()
  const [displayTime, setDisplayTime] = useState(false)
  const [displayBtn, setDisplayBtn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState<Nullable<Date>>(null)
  const [time, setTime] = useState<Nullable<any>>(null)
  const [AvailableDataFilteredByDate, setAvailableDataFilteredByDate] =
    useState<any[]>([])
  const [availableDataInDateTime, setAvailableDataInDateTime] = useState([])
  const { getAvailable } = useApi()

  useEffect(scrollToTop, [])

  const handleDateChange = (e: FormEvent<Date>) => {
    if (!e.value) {
      return
    }

    if (loading) {
      return
    }

    setDate(e.value)

    setDisplayBtn(false)
    setDisplayTime(true)
  }

  const handleMonthChange = async (e: CalendarMonthChangeEvent) => {
    setDisplayBtn(false)
    setDisplayTime(false)
    setLoading(true)
    await refetchAvailable(e.month, e.year)
  }

  const handleTimeChange = (e: FormEvent<any>) => {
    if (!e.value) {
      return
    }

    setTime(e.value)

    setOrder((prevValue) => {
      return { ...prevValue, event: e.value }
    })

    setDisplayBtn(true)
  }

  const refetchAvailable = async (month: number, year: number) => {
    month = month + 1
    const monthDisp = month.toString().length === 1 ? `0${month}` : `${month}`
    const date = `${monthDisp}/${year}`

    const available = await getAvailable(date, pkg.label.toLocaleLowerCase())

    if (available.error) {
      setAvailableDataInDateTime([])
      setAvailableDataFilteredByDate([])
      return
    }

    const data = available.data

    const availableDateTime = data.map((item: any) => {
      const dateTime = new Date()
      const date = item.start?.date ?? item.allDay
      const dateSplit = date.split('/')
      const day = parseInt(dateSplit[0])
      const month = parseInt(dateSplit[1]) - 1
      const year = parseInt(dateSplit[2])
      dateTime.setDate(day)
      dateTime.setMonth(month)
      dateTime.setFullYear(year)

      if (item.start?.timestamp) {
        const timeSplit = item.start.timestamp.split(':')
        const hour = parseInt(timeSplit[0])
        const minute = parseInt(timeSplit[1])
        dateTime.setHours(hour)
        dateTime.setMinutes(minute)
      }

      return dateTime
    })

    setAvailableDataInDateTime(availableDateTime)
    setAvailableDataFilteredByDate(data)
    setLoading(false)
  }

  useEffect(() => {
    let today = new Date()
    refetchAvailable(today.getMonth(), today.getFullYear())
  }, [])

  return (
    <div
      className={css({
        margin: '0 10%',
        mdDown: {
          margin: '0 1%',
        },
        '& > iframe': {
          overflow: 'hidden',
        },
      })}
    >
      <Typography
        className={css({ color: 'fonts.darkBlue', textAlign: 'center' })}
        as="h1"
      >
        Choisissez la date de votre séance photo
      </Typography>

      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          w: '100%',
          m: '2.5rem 0',
        })}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2.5rem',
            w: '90%',
            lgDown: {
              flexDirection: 'column',
            },
          })}
        >
          <MyCalendar
            minDate={new Date(new Date().setDate(new Date().getDate() + 8))}
            enabledDates={loading ? [] : availableDataInDateTime}
            value={date}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
          />

          {displayTime ? (
            <WorkingHours
              data={AvailableDataFilteredByDate}
              date={date}
              value={time}
              onChange={handleTimeChange}
            />
          ) : null}
        </div>
      </div>

      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          w: '100%',
          mt: '20px',
        })}
      >
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            w: '50%',
            lgDown: {
              w: '90%',
              flexDirection: 'column',
              gap: '1rem',
            },
          })}
        >
          <Button
            className={css({
              width: '20%',
              lgDown: {
                width: '100%',
              },
            })}
            onClick={back}
            variants={{ format: 'normal', visual: 'secondary' }}
          >
            Retour
          </Button>

          <Button
            variants={{ format: 'normal', visual: 'danger' }}
            onClick={() => navigate('/photographie/contact')}
          >
            Vous trouvez pas un créneau ? Contactez-Nous !
          </Button>

          <Button
            className={css({
              cursor: !displayBtn ? 'not-allowed !' : 'pointer !',
              opacity: displayBtn ? '1' : '0.5',
              width: '20%',
              lgDown: {
                width: '100%',
              },
            })}
            onClick={(e) => {
              displayBtn && next()
            }}
            variants={{ format: 'normal', visual: 'digit' }}
          >
            JE VALIDE
          </Button>
        </div>
      </div>
    </div>
  )
}

export { DateAndTimeSelection }
