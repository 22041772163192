import { css } from '../../../../styled-system/css';
import React from "react";
import { PackageImages } from './PackageImages';
import { Package } from '@dup/products';
import { Typography } from '../Typography';

const HeaderSummary = ({ pkg }: { pkg: Package }) => {
    return (
        <>
            <Typography className={css({
                color: 'fonts.darkBlue',
                textAlign: "center"
            })} as="h1">
                Récapitulatif de votre réservation
            </Typography>

            <PackageImages
                special={true}
                className={css({
                    m: '2.5rem 0',
                    p: '0 0 1.25rem 0 !important',
                    lgDown: { p: '0 0 .35rem 0 !' },
                })}
                id={pkg.id}
                amount={8}
                startAt={4}
            />
        </>
    );
}

export default HeaderSummary;