import { useOrderValue } from '../../atoms/order.atom'
import { BackgroundsSelection } from '../../views/BackgroundsSelection'
import { DateAndTimeSelection } from '../../views/DateAndTimeSelection'
import { OptionsSelection } from '../../views/OptionsSelection'
import { PackSelection } from '../../views/PackSelection'
import { Summary } from '../../views/Summary'
import { packages } from '@dup/products'
import React, { useEffect, useState } from 'react'
import { HeadFC, PageProps, navigate } from 'gatsby'
import { css } from '../../../styled-system/css'
import { Step, Stepper } from 'react-form-stepper'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import AbsoluteImages from '../../components/photographie/order/AbsoluteImages'
import Banner from '../../components/photographie/order/Banner'
import BottomOrder from '../../components/photographie/order/BottomOrder'
import HeaderSummary from '../../components/photographie/order/HeaderSummary'

const OrderPage: React.FC<PageProps> = () => {
  if (typeof window === 'undefined') {
    return null
  }

  const breakpoints = useBreakpoint()
  const currentOrder = useOrderValue()
  const [currentView, setCurrentView] = useState(0)
  const pkg = packages.find(
    (_package) => _package.id === currentOrder.packageId
  )

  useEffect(() => {
    if (!pkg) {
      navigate('/photographie/reservation')
    }
  }, [])

  if (!pkg) {
    navigate('/photographie/reservation')
    return null
  }

  const goToView = (view: number) => {
    if (currentView < view) {
      return
    }

    setCurrentView(view)
  }

  const views = [
    <PackSelection next={() => setCurrentView(1)} pkg={pkg} />,
    <OptionsSelection
      next={() => setCurrentView(2)}
      back={() => goToView(0)}
      pkg={pkg}
    />,
    <BackgroundsSelection
      next={() => setCurrentView(3)}
      back={() => goToView(1)}
      pkg={pkg}
    />,
    <DateAndTimeSelection
      next={() => setCurrentView(4)}
      back={() => goToView(2)}
      pkg={pkg}
    />,
    <Summary
      back={() => setCurrentView(3)}
    />,
  ]

  return (
    <HeaderAndFooterLayout>
      <div>
        {currentView !== 0 && !(breakpoints.md || breakpoints.xs) ? (
          <AbsoluteImages pkg={pkg} />
        ) : null}

        <h2
          className={css({
            marginTop: '2rem',
            marginBottom: '2rem',
            fontSize: '2.25rem',
            fontWeight: '300',
            color: '#594A2E',
            textAlign: 'center',
            lgDown: {
              fontSize: '1rem',
            },
          })}
        >
          Vous avez choisi :
          <span
            className={css({
              fontWeight: '600',
              fontFamily: 'Assistant !important',
            })}
          >
            Séance photo {pkg.label.toLowerCase()}
          </span>
        </h2>

        {currentView === 0 ? (
          <Banner pkg={pkg} />
        ) : null}

        <Stepper
          className={css({
            margin: '2.5rem 20rem',
            lgDown: { margin: '0', marginTop: '1rem', padding: '1rem !' },
          })}
          activeStep={currentView}
          connectorStateColors={true}
          connectorStyleConfig={{
            activeColor: '#000',
            completedColor: '#000',
            size: !breakpoints.md && !breakpoints.xs ? '.2rem' : '.15rem',
            disabledColor: 'rgb(224, 224, 224)',
            style: 'solid',
          }}
          styleConfig={{
            activeBgColor: '#000',
            activeTextColor: '#F2D98D',
            borderRadius: '50%',
            circleFontSize:
              !breakpoints.md && !breakpoints.xs ? '1rem' : '.75rem',
            completedBgColor: '#000',
            completedTextColor: '#F2D98D',
            fontWeight: '400',
            inactiveBgColor: 'rgb(224, 224, 224)',
            inactiveTextColor: '#6D758F',
            size: !breakpoints.md && !breakpoints.xs ? '3rem' : '2rem',
            labelFontSize:
              !breakpoints.md && !breakpoints.xs ? '1rem' : '.5rem',
          }}
        >
          <Step onClick={() => goToView(0)} label="Choix du pack" />
          <Step onClick={() => goToView(1)} label="Choix des options" />
          <Step onClick={() => goToView(2)} label="Choix des fonds" />
          <Step onClick={() => goToView(3)} label="Choix de la date" />
          <Step label="Résumé et paiment" />
        </Stepper>

        <div
          className={css({
            marginTop: currentView === 0 ? '1.25rem' : '5rem',
            lgDown: {
              marginTop: currentView === 0 ? '.25rem' : '5rem',
            },
          })}
        >
          {currentView === views.length - 1 ? (
            <HeaderSummary pkg={pkg} />
          ) : null}
          {views[currentView]}
        </div>

        <BottomOrder pkg={pkg} views={views} currentView={currentView} />
      </div>
    </HeaderAndFooterLayout>
  )
}

export default OrderPage

export const Head: HeadFC = () => <title>Réservation | Digit Universe</title>
