import React from 'react';
import { useOrderValue } from '../../../atoms/order.atom';
import { packages } from '@dup/products'
import { Typography } from '../Typography';
import { css } from '../../../../styled-system/css';
import ResponsiveImage from '../ResponsiveImage';

const OrderResume = () => {
    const order = useOrderValue()

    const pkg = packages.find((pk) => pk.id === order.packageId)

    if (!pkg) {
        return null
    }

    const sub = pkg.subs.find((sub) => sub.id === order.sub)

    const options = order.options

    const backgrounds = pkg.backgrounds.filter((bg) =>
        order.backgrounds.includes(bg.id)
    )

    const total =
        (backgrounds.reduce((a, b) => a + 2000, 0) - (2000 * Math.min(backgrounds.length, 2))) +
        options.Distance * 100 +
        (options.PhotosSup * 2000) +
        (sub?.price ?? 0)

    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                mt: '2rem',
            })}
        >
            <Typography
                className={css({ textAlign: 'center', color: 'fonts.darkBlue', fontWeight: "600" })}
                as="h2"
            >
                Vous réservez pour séance photo {pkg.label}
            </Typography>

            <div className={css({
                width: "100%",
                maxWidth: "45rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
            })}>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: "100%",
                    })}
                >
                    <Typography className={css({
                        fontSize: "1.25rem !",
                        fontWeight: "600",
                    })} as="h3">{sub?.label}</Typography>
                    <Typography className={css({
                        fontSize: "1.25rem !",
                    })} as="p">{(sub?.price ?? 0) / 100}€</Typography>
                </div>

                {(options.LifeStyle || options.PhotosSup) ? (
                    <div className={css({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexDirection: "column",
                        gap: "1rem",
                        width: "100%",
                    })}>
                        <Typography className={css({
                            fontSize: "1.25rem !",
                            alignSelf: "flex-start",
                            fontWeight: "600",
                        })} as="h3">Options :</Typography>
                        <div className={css({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            flexDirection: "column",
                            width: "100%",
                        })}>
                            {options.LifeStyle && (
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: "100%",
                                    })}
                                >
                                    <Typography className={css({
                                        fontSize: "1rem !",
                                        fontWeight: "400",
                                        marginLeft: "1rem",
                                    })} as="h4">Options LifeStyle ({options.Distance}km)</Typography>
                                    <Typography className={css({
                                        fontSize: "1rem !",
                                        fontWeight: "400",
                                        color: "#777"
                                    })} as="p">+ {options.Distance}€</Typography>
                                </div>
                            )}
                            {options.PhotosSup && (
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: "100%",
                                    })}
                                >
                                    <Typography className={css({
                                        fontSize: "1rem !",
                                        fontWeight: "400",
                                        marginLeft: "1rem",
                                    })} as="h4">Photo Supplémentaires (x{options.PhotosSup})</Typography>
                                    <Typography className={css({
                                        fontSize: "1rem !",
                                        fontWeight: "400",
                                        color: "#777"
                                    })} as="p">+ {options.PhotosSup * 20}€</Typography>
                                </div>
                            )}
                        </div>
                        <Typography className={css({
                            fontSize: "1.5rem !",
                            alignSelf: "flex-end",
                        })} as="h3">{options.Distance + (options.PhotosSup * 20)}€</Typography>
                    </div>
                ) : null}


                {backgrounds.length !== 0 ? (
                    <div className={css({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexDirection: "column",
                        gap: "1rem",
                        width: "100%",
                    })}>
                        <Typography className={css({
                            fontSize: "1.25rem !",
                            alignSelf: "flex-start",
                            fontWeight: "600",
                        })} as="h3">Vos fonds :</Typography>
                        <div className={css({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "1rem",
                            flexWrap: "wrap",
                            width: "100%",
                        })}>
                            {backgrounds.map((background, index) => (
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDir: "column",
                                    })}
                                    key={background.id}
                                >
                                    <ResponsiveImage
                                        relativePath={`themes/${background.image}`}
                                        key={background.id}
                                        className={css({
                                            h: '6rem',
                                            objectFit: 'cover',
                                            w: '10rem',
                                            borderRadius: "6px"
                                        })}
                                    />
                                    <Typography className={css({
                                        fontSize: "1rem !",
                                        fontWeight: "400",
                                        color: "#777"
                                    })} as="p">{index < 2 ? "Inclus" : "+ 20€"}</Typography>
                                </div>
                            ))}
                        </div>
                        <Typography className={css({
                            fontSize: "1.5rem !",
                            alignSelf: "flex-end",
                        })} as="h3">{(() => {
                            const price = (backgrounds.reduce((a, b) => a + 2000, 0) - (2000 * Math.min(backgrounds.length, 2))) / 100

                            return price > 0 ? `${price}€` : "Inclus"
                        })()}</Typography>
                    </div>
                ) : null}

            </div>

            <hr
                className={css({
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    w: '100%',
                    alignSelf: 'center',
                })}
            />

            <div className={css({
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            })}>
                <div className={css({
                    display: "flex",
                    justifyContent: "space-between",
                })}>
                    <Typography className={css({ fontWeight: "600", fontSize: "1.25rem !" })} as="h3">
                        Sous-Total
                    </Typography>
                    <Typography className={css({ fontSize: "1.25rem !" })} as="p">
                        {total / 100}€
                    </Typography>
                </div>

                {order.allowShareImages ? (
                    <div className={css({
                        display: "flex",
                        justifyContent: "space-between",
                    })}>
                        <Typography className={css({ fontSize: "1rem !" })} as="h3">
                            Réduction autorisation de publication des photos
                        </Typography>
                        <Typography className={css({ fontSize: "1rem !" })} as="p">
                            - 20€
                        </Typography>
                    </div>
                ) : null}
            </div>
            <hr
                className={css({
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    w: '100%',
                    alignSelf: 'center',
                })}
            />
            <div>
                <div className={css({
                    display: "flex",
                    justifyContent: "space-between",
                })}>
                    <Typography className={css({ fontWeight: "600", fontSize: "1.25rem !" })} as="h3">
                        Total
                    </Typography>
                    <Typography className={css({ fontSize: "1.25rem !" })} as="p">
                        {total / 100 - (order.allowShareImages ? 20 : 0)}€
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default OrderResume;