import React from 'react'
import { css } from '../../../../styled-system/css';
import { PackageImages } from './PackageImages';
import { Hr } from '../Hr';
import { Typography } from '../Typography';
import { Feedbacks_photos } from '../Feedbacks';
import { Faq } from '../Faq';
import { Package } from '@dup/products';
import { SeanceType } from '../../../consts';

const BottomOrder = ({ pkg, currentView, views }: { pkg: Package, currentView: number, views: React.JSX.Element[] }) => {
    return (
        <div
            className={css({
                display: 'flex',
                flexDir: 'column',
                alignItems: 'center',
                width: '100%',
            })}
        >
            <PackageImages
                className={css({ m: '2.5rem 0', w: '100%' })}
                id={pkg.id}
                amount={4}
            />

            {currentView !== views.length - 1 ? (
                <>
                    <Hr />

                    <div
                        className={css({
                            width: '90%',
                            margin: '0 auto 0',
                        })}
                    >
                        <div
                            className={css({
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '1.875rem',
                                padding: '1.25rem',
                                width: '100%',
                            })}
                        >
                            <Typography
                                className={css({
                                    color: '#19213D',
                                    fontWeight: '500',
                                    textAlign: 'center',
                                    fontSize: '2rem',
                                    '@media (max-width: 768px)': {
                                        fontSize: '1.5rem',
                                    },
                                })}
                                as="h1"
                            >
                                Ils ont aimé leur séance photo {pkg.label.toLowerCase()}{' '}
                                avec Digit Universe
                            </Typography>

                            <Feedbacks_photos title={''} seanceType={pkg.label} />
                        </div>
                    </div>

                    <PackageImages
                        special={true}
                        className={css({
                            m: '2.5rem 0',
                            p: '0 0 1.25rem 0 !important',
                            lgDown: { p: '0 0 .35rem 0 !' },
                        })}
                        id={pkg.id}
                        amount={8}
                        startAt={4}
                    />
                </>
            ) : null}

            {currentView == 0 ? (
                <>
                    <Hr />
                    <Faq
                        seanceType={pkg.label as SeanceType}
                        className={css({
                            marginBottom: '5rem',
                            marginLeft: '5rem',
                            marginRight: '5rem',
                        })}
                    />
                </>
            ) : null}
        </div>
    );
}

export default BottomOrder;