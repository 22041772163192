import React, { useEffect, useRef } from 'react';

export const useUpdateEffect = (fn: () => void, deps?: any) => {
    const mounted = useRef(false);
    return useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }

        return fn && fn();
    }, deps);
};