import React, { ChangeEvent, PropsWithChildren } from 'react'
import { css } from '../../../../styled-system/css'
import { Typography } from '../Typography'

type CheckboxProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox = ({
  children,
  onChange,
}: PropsWithChildren<CheckboxProps>) => {
  return (
    <div className={css({ display: 'flex', alignItems: 'center', gap: '5px' })}>
      <input
        className={css({ cursor: 'pointer' })}
        onChange={onChange}
        type="checkbox"
      />
      <Typography as="p">{children}</Typography>
    </div>
  )
}
