import React, { useEffect, useState, memo, useRef, forwardRef, useImperativeHandle } from 'react';
import { css } from '../../../../styled-system/css';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { usePrevious } from '../../../hooks/usePrevious';
import { useMountEffect } from '../../../hooks/useMountEffect';
import { useUpdateEffect } from '../../../hooks/useUpdateEffect';

// #region Types
export declare type Nullable<T = void> = T | null | undefined;

export interface CalendarSelectEvent {
    originalEvent: React.SyntheticEvent;
    value: Nullable<Date | Date[] | string>;
}

export interface CalendarViewChangeEvent {
    originalEvent: React.SyntheticEvent;
    value: Date;
}

export interface CalendarMonthChangeEvent {
    month: number;
    year: number;
}

interface FormTarget<T = any> {
    name?: string;
    id?: string;
    value: Nullable<T>;
    checked?: boolean;
    [key: string]: any;
}

export interface FormEvent<T = any, E = React.SyntheticEvent> {
    originalEvent?: E;
    value: Nullable<T>;
    checked?: boolean;
    stopPropagation(): void;
    preventDefault(): void;
    target: FormTarget<T>;
}
// #endregion

export const MyCalendar = memo(forwardRef((inProps: any, ref) => {
    //#region Variables
    const props: {
        value: any,
        onViewDateChange?: (event: CalendarViewChangeEvent) => void,
        viewDate?: any,
        disabledDates?: Date[],
        disabledDays?: any,
        enabledDates?: Date[],
        onSelect?: (event: CalendarSelectEvent) => void,
        onChange?: (event: FormEvent<Date>) => void,
        onMonthChange?: (event: CalendarMonthChangeEvent) => void,
        minDate?: Date,
        maxDate?: Date,
        view: string
    } = {
        ...inProps,
        view: inProps.view ?? 'date'
    }

    const [viewDateState, setViewDateState] = useState<any>(null);
    const [currentView, setCurrentView] = useState('date')
    const [currentMonth, setCurrentMonth] = useState<number>(0)
    const [currentYear, setCurrentYear] = useState<number>(0)

    const overlayRef = useRef<HTMLDivElement>(null);

    const viewStateChanged = useRef(false);
    const viewChangedWithKeyDown = useRef(false);

    const onChangeRef = useRef<((event: FormEvent<Date>) => void) | undefined>(undefined);

    const previousValue = usePrevious(props.value);
    // #endregion

    // #region Utils
    const isValidDate = (date: any) => {
        return date instanceof Date && !isNaN(date as any);
    }

    const cloneDate = (date: any) => {
        return isValidDate(date) ? new Date(date.valueOf()) : date;
    }

    const getViewDate = (date?: any) => {
        let propValue = props.value;
        let viewDate = date || (props.onViewDateChange ? props.viewDate : viewDateState);

        if (Array.isArray(propValue)) {
            propValue = propValue[0];
        }

        return viewDate && isValidDate(viewDate) ? viewDate : propValue && isValidDate(propValue) ? propValue : new Date();
    }

    const isDayDisabled = (day: number, month: number, year: number) => {
        let isDisabled = false;

        if (props.disabledDates) {
            if (props.disabledDates.some((d: Date) => d.getFullYear() === year && d.getMonth() === month && d.getDate() === day)) {
                isDisabled = true;
            }
        }

        if (!isDisabled && props.disabledDays && currentView === 'date') {
            let weekday = new Date(year, month, day);
            let weekdayNumber = weekday.getDay();

            if (props.disabledDays.indexOf(weekdayNumber) !== -1) {
                isDisabled = true;
            }
        }

        if (props.enabledDates) {
            const isEnabled = props.enabledDates.some((d: Date) => d.getFullYear() === year && d.getMonth() === month && d.getDate() === day);

            if (isEnabled) {
                isDisabled = false;
            } else if (!props.disabledDays && !props.disabledDates) {
                isDisabled = true;
            }
        }

        return isDisabled;
    }

    const isSelectable = (day: number, month: number, year: number) => {
        let validDay = true;
        let validMin = true;
        let validMax = true;

        if (props.disabledDates || props.enabledDates || props.disabledDays) {
            validDay = !isDayDisabled(day, month, year);
        }


        if (props.minDate) {
            if (props.minDate.getFullYear() > year) {
                validMin = false;
            } else if (props.minDate.getFullYear() === year) {
                if (month > -1 && props.minDate.getMonth() > month) {
                    validMin = false;
                } else if (month > -1 && props.minDate.getMonth() === month) {
                    if (day > 0 && props.minDate.getDate() > day) {
                        validMin = false;
                    }
                }
            }
        }

        if (props.maxDate) {
            if (props.maxDate.getFullYear() < year) {
                validMax = false;
            } else if (props.maxDate.getFullYear() === year) {
                if (month > -1 && props.maxDate.getMonth() < month) {
                    validMax = false;
                } else if (month > -1 && props.maxDate.getMonth() === month) {
                    if (day > 0 && props.maxDate.getDate() < day) {
                        validMax = false;
                    }
                }
            }
        }

        return validDay && validMin && validMax;
    }

    const updateViewDate = (event: any, value: any) => {
        if (props.onViewDateChange && event) {
            props.onViewDateChange({
                originalEvent: event,
                value
            });
        } else {
            viewStateChanged.current = true;
            setViewDateState(value);
        }

        if (value && props.onSelect) {
            const day = value.getDate();
            const month = value.getMonth();
            const year = value.getFullYear();

            onDateSelect(event, { day, month, year, selectable: isSelectable(day, month, year) });
        }
    }

    const getCurrentDateTime = () => {
        return props.value && props.value instanceof Date ? cloneDate(props.value) : getViewDate();
    }

    const domFind = (element: any, selector: any) => {
        return element ? Array.from(element.querySelectorAll(selector)) : [];
    }

    const domFindSingle = (element: any, selector: any) => {
        return element ? element.querySelector(selector) : null;
    }

    const domIndex = (element: any) => {
        if (element) {
            let children = element.parentNode.childNodes;
            let num = 0;

            for (let i = 0; i < children.length; i++) {
                if (children[i] === element) {
                    return num;
                }

                if (children[i].nodeType === 1) {
                    num++;
                }
            }
        }

        return -1;
    }

    const domAddClass = (element: any, className: any) => {
        if (element && className) {
            if (element.classList) {
                element.classList.add(className);
            } else {
                element.className = element.className + (' ' + className);
            }
        }
    }

    const domRemoveClass = (element: any, className: any) => {
        if (element && className) {
            if (element.classList) {
                element.classList.remove(className);
            } else {
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    }

    const isToday = (today: any, day: number, month: number, year: number) => {
        return today.getDate() === day && today.getMonth() === month && today.getFullYear() === year;
    }

    const getFirstDayOfMonthIndex = (month: number, year: number) => {
        let day = new Date();

        day.setDate(1);
        day.setMonth(month);
        day.setFullYear(year);

        let dayIndex = day.getDay() + 6;

        return dayIndex >= 7 ? dayIndex - 7 : dayIndex;
    }

    const daylightSavingAdjust = (date: any) => {
        if (!date) {
            return null;
        }

        date.setHours(date.getHours() > 12 ? date.getHours() + 2 : 0);

        return date;
    }

    const getDaysCountInMonth = (month: number, year: number) => {
        return 32 - daylightSavingAdjust(new Date(year, month, 32)).getDate();
    }

    const getPreviousMonthAndYear = (month: number, year: number) => {
        let m;
        let y;

        if (month === 0) {
            m = 11;
            y = year - 1;
        } else {
            m = month - 1;
            y = year;
        }

        return { month: m, year: y };
    }

    const getDaysCountInPrevMonth = (month: number, year: number) => {
        let prev = getPreviousMonthAndYear(month, year);

        return getDaysCountInMonth(prev.month, prev.year);
    }

    const getNextMonthAndYear = (month: number, year: number) => {
        let m;
        let y;

        if (month === 11) {
            m = 0;
            y = year + 1;
        } else {
            m = month + 1;
            y = year;
        }

        return { month: m, year: y };
    }

    const createMonthMeta = (month: number, year: number) => {
        let dates = [];
        let firstDay = getFirstDayOfMonthIndex(month, year);
        let daysLength = getDaysCountInMonth(month, year);
        let prevMonthDaysLength = getDaysCountInPrevMonth(month, year);
        let dayNo = 1;
        let today = new Date();
        let monthRows = Math.ceil((daysLength + firstDay) / 7);

        for (let i = 0; i < monthRows; i++) {
            let week = [];

            if (i === 0) {
                for (let j = prevMonthDaysLength - firstDay + 1; j <= prevMonthDaysLength; j++) {
                    let prev = getPreviousMonthAndYear(month, year);

                    week.push({
                        day: j,
                        month: prev.month,
                        year: prev.year,
                        otherMonth: true,
                        today: isToday(today, j, prev.month, prev.year),
                        selectable: isSelectable(j, prev.month, prev.year)
                    });
                }

                let remainingDaysLength = 7 - week.length;

                for (let j = 0; j < remainingDaysLength; j++) {
                    week.push({
                        day: dayNo,
                        month,
                        year,
                        today: isToday(today, dayNo, month, year),
                        selectable: isSelectable(dayNo, month, year)
                    });

                    dayNo++;
                }
            } else {
                for (let j = 0; j < 7; j++) {
                    if (dayNo > daysLength) {
                        let next = getNextMonthAndYear(month, year);

                        week.push({
                            day: dayNo - daysLength,
                            month: next.month,
                            year: next.year,
                            otherMonth: true,
                            today: isToday(today, dayNo - daysLength, next.month, next.year),
                            selectable: isSelectable(dayNo - daysLength, next.month, next.year)
                        });
                    } else {
                        week.push({
                            day: dayNo,
                            month,
                            year,
                            today: isToday(today, dayNo, month, year),
                            selectable: isSelectable(dayNo, month, year)
                        });
                    }

                    dayNo++;
                }
            }

            dates.push(week);
        }

        return {
            month: month,
            year: year,
            dates: dates,
        }
    }

    const createMonthsMeta = (month: number, year: number) => {
        let months = [];
        let m = month;
        let y = year;

        if (m > 11) {
            m = (m % 11) - 1;
            y = year + 1;
        }

        months.push(createMonthMeta(m, y));

        return months;
    }

    const createWeekDaysMeta = () => {
        let weekDays = [];
        let dayIndex = 1;
        const dayNamesMin = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];

        for (let i = 0; i < 7; i++) {
            weekDays.push(dayNamesMin[dayIndex]);
            dayIndex = dayIndex === 6 ? 0 : ++dayIndex;
        }

        return weekDays;
    }

    const yearPickerValues = () => {
        let yearPickerValues = [];
        let base = (currentYear ?? 0) - ((currentYear ?? 0) % 10);

        for (let i = 0; i < 10; i++) {
            yearPickerValues.push(base + i);
        }

        return yearPickerValues;
    };

    const isDateEquals = (value: any, dateMeta: any) => {
        if (value && value instanceof Date) {
            return value.getDate() === dateMeta.day && value.getMonth() === dateMeta.month && value.getFullYear() === dateMeta.year;
        }

        return false;
    }

    const isSelected = (dateMeta: any) => {
        if (props.value) {
            return isDateEquals(props.value, dateMeta);
        } else {
            return false;
        }
    }

    const navigateToMonth = (backward: boolean, groupIndex: number, event: any) => {
        if (backward) {
            navBackward(event);
        } else {
            navForward(event);
        }
    }

    const initFocusableCell = () => {
        let cell;

        if (currentView === 'month') {
            const cells = domFind(overlayRef.current, '.monthpicker .month');
            const selectedCell = domFindSingle(overlayRef.current, '.monthpicker .month.highlight');

            cells.forEach((cell: any) => (cell.tabIndex = -1));
            cell = selectedCell || cells[0];
        } else {
            cell = domFindSingle(overlayRef.current, 'span.highlight');

            if (!cell) {
                const todayCell = domFindSingle(overlayRef.current, 'td.today span:not(.disabled)');

                cell = todayCell || domFindSingle(overlayRef.current, 'table td span:not(.disabled)');
            }
        }

        if (cell) {
            cell.tabIndex = '0';
        }
    }

    const setValue = (propValue: any) => {
        if (Array.isArray(propValue)) {
            propValue = propValue[0];
        }

        let prevPropValue = previousValue;

        if (Array.isArray(prevPropValue)) {
            prevPropValue = prevPropValue[0];
        }

        let viewDate = props.viewDate && isValidDate(props.viewDate) ? props.viewDate : propValue && isValidDate(propValue) ? propValue : new Date();

        setViewDateState(viewDate);
        viewStateChanged.current = true;
    };

    const focusToFirstCell = () => {
        if (currentView) {
            let cell;

            if (currentView === 'date') {
                cell = domFindSingle(overlayRef.current, 'span.highlight');

                if (!cell) {
                    const todayCell = domFindSingle(overlayRef.current, 'td.today span:not(.disabled)');

                    cell = todayCell || domFindSingle(overlayRef.current, 'table td span:not(.disabled)');
                }
            } else if (currentView === 'month' || currentView === 'year') {
                cell = domFindSingle(overlayRef.current, 'span.highlight');

                if (!cell) {
                    cell = domFindSingle(overlayRef.current, `.${currentView}picker .${currentView}:not(.disabled)`);
                }
            }

            if (cell) {
                cell.tabIndex = '0';
                cell && cell.focus();
            }
        }
    };

    const getOuterWidth = (el: any, margin?: any) => {
        if (el) {
            let width = el.getBoundingClientRect().width || el.offsetWidth;

            if (margin) {
                let style = getComputedStyle(el);

                width = width + (parseFloat(style.marginLeft) + parseFloat(style.marginRight));
            }

            return width;
        }

        return 0;
    }

    const monthPickerValues = () => {
        let monthPickerValues = [];
        let monthNamesShort = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];

        for (let i = 0; i <= 11; i++) {
            monthPickerValues.push(monthNamesShort[i]);
        }

        return monthPickerValues;
    }

    const isComparable = () => {
        return props.value != null && typeof props.value !== 'string';
    }

    const isMonthSelected = (month: number) => {
        if (!isComparable()) return false;

        return props.value.getMonth() === month && props.value.getFullYear() === currentYear;
    }

    const isYearSelected = (year: number) => {
        if (!isComparable()) return false;

        return props.value.getFullYear() === year;
    }

    const setNavigationState = (newViewDate: any) => {
        if (!newViewDate || props.view !== 'date' || !overlayRef.current) {
            return;
        }

        const navPrev = domFindSingle(overlayRef.current, '.prevbutton');
        const navNext = domFindSingle(overlayRef.current, '.nextbutton');

        if (props.minDate) {
            let firstDayOfMonth = cloneDate(newViewDate);

            if (firstDayOfMonth.getMonth() === 0) {
                firstDayOfMonth.setMonth(11, 1);
                firstDayOfMonth.setFullYear(firstDayOfMonth.getFullYear() - 1);
            } else {
                firstDayOfMonth.setMonth(firstDayOfMonth.getMonth(), 1);
            }

            firstDayOfMonth.setHours(0);
            firstDayOfMonth.setMinutes(0);
            firstDayOfMonth.setSeconds(0);

            if (props.minDate > firstDayOfMonth) {
                domAddClass(navPrev, 'disabled');
            } else {
                domRemoveClass(navPrev, 'disabled');
            }
        }

        if (props.maxDate) {
            let lastDayOfMonth = cloneDate(newViewDate);

            if (lastDayOfMonth.getMonth() === 11) {
                lastDayOfMonth.setMonth(0, 1);
                lastDayOfMonth.setFullYear(lastDayOfMonth.getFullYear() + 1);
            } else {
                lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1, 1);
            }

            lastDayOfMonth.setHours(0);
            lastDayOfMonth.setMinutes(0);
            lastDayOfMonth.setSeconds(0);
            lastDayOfMonth.setSeconds(-1);

            if (props.maxDate < lastDayOfMonth) {
                domAddClass(navNext, 'disabled');
            } else {
                domRemoveClass(navNext, 'disabled');
            }
        }
    };

    const isMonthYearDisabled = (month: number, year: number) => {

        if (month >= 0) {
            if (props.minDate) {
                return props.minDate.getFullYear() > year || (props.minDate.getFullYear() === year && props.minDate.getMonth() > month);
            }

            if (props.maxDate) {
                return props.maxDate.getFullYear() < year || (props.maxDate.getFullYear() === year && props.maxDate.getMonth() < month);
            }
        } else {
            if (props.minDate) {
                return props.minDate.getFullYear() > year;
            }

            if (props.maxDate) {
                return props.maxDate.getFullYear() < year;
            }
        }

        return false;
    }
    // #endregion

    // #region Events
    const onDateSelect = (event: React.SyntheticEvent, dateMeta: any) => {
        if (!event) {
            return;
        }

        if (!dateMeta.selectable) {
            event.preventDefault();
            return;
        }

        domFind(overlayRef.current, "table td span:not(.disabled)").forEach((cell: any) => (cell.tabIndex = -1));
        (event.currentTarget as HTMLElement).focus();

        selectDate(event, dateMeta);

        event.preventDefault();
    }

    const onPrevBtnClick = (event: any) => {
        const viewDate = cloneDate(getViewDate())
        viewDate.setDate(1);

        if (props.minDate && viewDate < props.minDate) {
            event.preventDefault();
            return;
        }

        navBackward(event)
    }

    const onNextBtnClick = (event: any) => {
        const viewDate = cloneDate(getViewDate())
        viewDate.setDate(1);

        if (props.maxDate && viewDate > props.maxDate) {
            event.preventDefault();
            return;
        }

        navForward(event)
    }

    const onContainerButtonKeydown = (event: any) => {
        switch (event.code) {
            case 'Escape':
                event.preventDefault();
                break;

            default:
                break;
        }
    }

    const switchToMonthView = (event: any) => {
        if (event && event.code && (event.code === 'Enter' || event.code === 'Space' || event.code === 'NumpadEnter')) {
            viewChangedWithKeyDown.current = true;
        }

        setCurrentView('month');
        event.preventDefault();
    }

    const switchToYearView = (event: any) => {
        if (event && event.code && (event.code === 'Enter' || event.code === 'Space' || event.code === 'NumpadEnter')) {
            viewChangedWithKeyDown.current = true;
        }

        setCurrentView('year');
        event.preventDefault();
    }

    const selectDate = (event: React.SyntheticEvent, dateMeta: any) => {
        let date = new Date(dateMeta.year, dateMeta.month, dateMeta.day);

        if (props.minDate && props.minDate > date) {
            date = props.minDate;
        }

        if (props.maxDate && props.maxDate < date) {
            date = props.maxDate;
        }

        updateModel(event, date);

        if (props.onSelect) {
            props.onSelect({
                originalEvent: event,
                value: date
            });
        }
    }

    const onDateCellKeydown = (event: React.KeyboardEvent<HTMLSpanElement>, date: any, groupIndex: number) => {
        const cellContent = event.currentTarget;
        const cell = cellContent.parentElement;
        const cellIndex = domIndex(cell);

        switch (event.code) {
            case 'ArrowDown': {
                cellContent.tabIndex = -1;

                let nextRow = cell?.parentElement?.nextElementSibling;

                if (nextRow) {
                    let tableRowIndex = domIndex(cell?.parentElement);
                    const tableRows = Array.from(cell?.parentElement?.parentElement?.children ?? []);
                    const nextTableRows = tableRows.slice(tableRowIndex + 1);

                    let hasNextFocusableDate = nextTableRows.find((el: any) => {
                        let focusCell = el.children[cellIndex].children[0];

                        return !focusCell.className.contains('disabled');
                    });

                    if (hasNextFocusableDate) {
                        let focusCell: HTMLElement = hasNextFocusableDate.children[cellIndex].children[0] as HTMLElement;

                        focusCell.tabIndex = 0;
                        focusCell.focus();
                    } else {
                        navForward(event);
                    }
                } else {
                    navForward(event);
                }

                event.preventDefault();
                break;
            }

            case 'ArrowUp': {
                cellContent.tabIndex = -1;

                if (!event.altKey) {
                    let prevRow = cell?.parentElement?.previousElementSibling;

                    if (prevRow) {
                        let tableRowIndex = domIndex(cell?.parentElement);
                        const tableRows = Array.from(cell?.parentElement?.parentElement?.children ?? []);
                        const prevTableRows = tableRows.slice(0, tableRowIndex).reverse();

                        let hasNextFocusableDate = prevTableRows.find((el: any) => {
                            let focusCell = el.children[cellIndex].children[0];

                            return !focusCell.className.contains('disabled');
                        });

                        if (hasNextFocusableDate) {
                            let focusCell: HTMLElement = hasNextFocusableDate.children[cellIndex].children[0] as HTMLElement;

                            focusCell.tabIndex = 0;
                            focusCell.focus();
                        } else {
                            navBackward(event);
                        }
                    } else {
                        navBackward(event);
                    }
                }

                event.preventDefault();
                break;
            }

            case 'ArrowLeft': {
                cellContent.tabIndex = -1;
                let prevCell = cell?.previousElementSibling;

                if (prevCell) {
                    const cells = Array.from(cell?.parentElement?.children ?? []);
                    const prevCells = cells.slice(0, cellIndex).reverse();

                    let hasNextFocusableDate = prevCells.find((el: any) => {
                        let focusCell = el.children[0];

                        return !focusCell.className.contains('disabled');
                    });

                    if (hasNextFocusableDate) {
                        let focusCell = hasNextFocusableDate.children[0] as HTMLElement;

                        focusCell.tabIndex = 0;
                        focusCell.focus();
                    } else {
                        navigateToMonth(true, groupIndex, event);
                    }
                } else {
                    navigateToMonth(true, groupIndex, event);
                }

                event.preventDefault();
                break;
            }

            case 'ArrowRight': {
                cellContent.tabIndex = -1;
                let nextCell = cell?.nextElementSibling;

                if (nextCell) {
                    const cells = Array.from(cell?.parentElement?.children ?? []);
                    const nextCells = cells.slice(cellIndex + 1);
                    let hasNextFocusableDate = nextCells.find((el: any) => {
                        let focusCell = el.children[0];

                        return !focusCell.className.contains('disabled');
                    });

                    if (hasNextFocusableDate) {
                        let focusCell = hasNextFocusableDate.children[0] as HTMLElement;

                        focusCell.tabIndex = 0;
                        focusCell.focus();
                    } else {
                        navigateToMonth(false, groupIndex, event);
                    }
                } else {
                    navigateToMonth(false, groupIndex, event);
                }

                event.preventDefault();
                break;
            }

            case 'Enter':
            case 'NumpadEnter':

            case 'Space': {
                onDateSelect(event, date);
                event.preventDefault();
                break;
            }

            case 'Escape': {
                event.preventDefault();
                break;
            }

            case 'PageUp': {
                cellContent.tabIndex = -1;

                if (event.shiftKey) {
                    navBackward(event);
                } else {
                    navigateToMonth(true, groupIndex, event);
                }

                event.preventDefault();
                break;
            }

            case 'PageDown': {
                cellContent.tabIndex = -1;

                if (event.shiftKey) {
                    navForward(event);
                } else {
                    navigateToMonth(false, groupIndex, event);
                }

                event.preventDefault();
                break;
            }

            default:
                break;
        }
    }

    const onMonthSelect = (event: React.SyntheticEvent, month: number) => {
        if (isMonthYearDisabled(month, currentYear)) { event.preventDefault(); return; }
        setCurrentMonth(month);
        createMonthsMeta(month, currentYear);
        const currentDate = cloneDate(getCurrentDateTime());

        currentDate.setDate(1);
        currentDate.setMonth(month);
        currentDate.setYear(currentYear);

        setViewDateState(currentDate);
        setCurrentView('date');
        props.onMonthChange && props.onMonthChange({ month: month, year: currentYear });

        updateViewDate(event, currentDate);
    }

    const onMonthCellKeydown = (event: React.KeyboardEvent<HTMLSpanElement>, month: number) => {
        const cell = event.currentTarget;

        switch (event.code) {
            //arrows
            case 'ArrowUp':

            case 'ArrowDown': {
                cell.tabIndex = -1;
                const cells = cell.parentElement?.children;
                const cellIndex = domIndex(cell);
                const nextCell = cells && (cells[event.which === 40 ? cellIndex + 3 : cellIndex - 3]) as HTMLElement;

                if (nextCell) {
                    nextCell.tabIndex = 0;
                    nextCell.focus();
                }

                event.preventDefault();
                break;
            }

            case 'ArrowLeft': {
                cell.tabIndex = -1;
                const prevCell = cell.previousElementSibling as HTMLElement;

                if (prevCell) {
                    prevCell.tabIndex = 0;
                    prevCell.focus();
                } else {
                    navBackward(event);
                }

                event.preventDefault();
                break;
            }

            case 'ArrowRight': {
                cell.tabIndex = -1;
                const nextCell = cell.nextElementSibling as HTMLElement;

                if (nextCell) {
                    nextCell.tabIndex = 0;
                    nextCell.focus();
                } else {
                    navForward(event);
                }

                event.preventDefault();
                break;
            }

            case 'PageUp': {
                if (event.shiftKey) {
                    return;
                }

                navBackward(event);

                break;
            }

            case 'PageDown': {
                if (event.shiftKey) {
                    return;
                }

                navForward(event);

                break;
            }

            case 'Enter':
            case 'NumpadEnter':

            case 'Space': {
                if (props.view !== 'month') {
                    viewChangedWithKeyDown.current = true;
                }

                onMonthSelect(event, month);
                event.preventDefault();
                break;
            }

            default:
                break;
        }
    }

    const onYearSelect = (event: React.SyntheticEvent, year: number) => {
        if (isMonthYearDisabled(-1, year)) { event.preventDefault(); return; }
        setCurrentYear(year);
        setCurrentView('month');
        props.onMonthChange && props.onMonthChange({ month: currentMonth + 1, year: year });
    }

    const onYearCellKeydown = (event: React.KeyboardEvent<HTMLSpanElement>, year: number) => {
        const cell = event.currentTarget;

        switch (event.code) {
            //arrows
            case 'ArrowUp':

            case 'ArrowDown': {
                cell.tabIndex = -1;
                let cells = cell.parentElement?.children;
                let cellIndex = domIndex(cell);
                let nextCell = cells && (cells[event.code === 'ArrowDown' ? cellIndex + 2 : cellIndex - 2]) as HTMLElement;

                if (nextCell) {
                    nextCell.tabIndex = 0;
                    nextCell.focus();
                }

                event.preventDefault();
                break;
            }

            case 'ArrowLeft': {
                cell.tabIndex = -1;
                let prevCell = cell.previousElementSibling as HTMLElement;

                if (prevCell) {
                    prevCell.tabIndex = 0;
                    prevCell.focus();
                } else {
                    navBackward(event);
                }

                event.preventDefault();
                break;
            }

            case 'ArrowRight': {
                cell.tabIndex = -1;
                let nextCell = cell.nextElementSibling as HTMLElement;

                if (nextCell) {
                    nextCell.tabIndex = 0;
                    nextCell.focus();
                } else {
                    navForward(event);
                }

                event.preventDefault();
                break;
            }

            case 'PageUp': {
                if (event.shiftKey) {
                    return;
                }

                navBackward(event);

                break;
            }

            case 'PageDown': {
                if (event.shiftKey) {
                    return;
                }

                navForward(event);

                break;
            }

            case 'Enter':
            case 'NumpadEnter':

            case 'Space': {
                if (props.view !== 'year') {
                    viewChangedWithKeyDown.current = true;
                }

                onYearSelect(event, year);
                event.preventDefault();
                break;
            }

            default:
                break;
        }
    }
    // #endregion

    // #region Navigation
    const navBackward = (event: React.KeyboardEvent<HTMLSpanElement>) => {
        let newViewDate = cloneDate(getViewDate());

        newViewDate.setDate(1);

        if (currentView === 'date') {
            if (newViewDate.getMonth() === 0) {
                const newYear = decrementYear();

                newViewDate.setMonth(11);
                newViewDate.setFullYear(newYear);
                props.onMonthChange && props.onMonthChange({ month: 11, year: newYear });
                setCurrentMonth(11);
            } else {
                newViewDate.setMonth(newViewDate.getMonth() - 1);
                props.onMonthChange && props.onMonthChange({ month: currentMonth - 1, year: currentYear });
                setCurrentMonth((prevState) => prevState - 1);
            }
        } else if (currentView === 'month') {
            let newYear = newViewDate.getFullYear() - 1;

            newViewDate.setFullYear(newYear);
        }

        if (currentView === 'month') {
            newViewDate.setFullYear(decrementYear());
        } else if (currentView === 'year') {
            newViewDate.setFullYear(decrementDecade());
        }

        updateViewDate(event, newViewDate);

        event.preventDefault();
    }

    const navForward = (event: React.KeyboardEvent<HTMLSpanElement>) => {

        let newViewDate = cloneDate(getViewDate());

        newViewDate.setDate(1);

        if (currentView === 'date') {
            if (newViewDate.getMonth() === 11) {
                const newYear = incrementYear();

                newViewDate.setMonth(0);
                newViewDate.setFullYear(newYear);
                props.onMonthChange && props.onMonthChange({ month: 0, year: newYear });
                setCurrentMonth(0);
            } else {
                newViewDate.setMonth(newViewDate.getMonth() + 1);
                props.onMonthChange && props.onMonthChange({ month: currentMonth + 1, year: currentYear });
                setCurrentMonth((prevState) => prevState + 1);
            }
        } else if (currentView === 'month') {
            let newYear = newViewDate.getFullYear() + 1;

            newViewDate.setFullYear(newYear);
        }

        if (currentView === 'month') {
            newViewDate.setFullYear(incrementYear());
        } else if (currentView === 'year') {
            newViewDate.setFullYear(incrementDecade());
        }

        updateViewDate(event, newViewDate);

        event.preventDefault();
    }
    //#endregion

    // #region Date Manipulation
    const decrementYear = () => {
        const _currentYear = (currentYear ?? 0) - 1;
        setCurrentYear(_currentYear);

        return _currentYear;
    }

    const incrementYear = () => {
        const _currentYear = (currentYear ?? 0) + 1;
        setCurrentYear(_currentYear);

        return _currentYear;
    }

    const decrementDecade = () => {
        const _currentYear = (currentYear ?? 0) - 10;
        setCurrentYear(_currentYear);

        return _currentYear;
    }

    const incrementDecade = () => {
        const _currentYear = (currentYear ?? 0) + 10;
        setCurrentYear(_currentYear);

        return _currentYear;
    }
    //#endregion

    // #region Render
    const updateModel = (event: any, value: any) => {
        if (props.onChange) {
            const newValue = cloneDate(value);

            viewStateChanged.current = true;

            if (onChangeRef.current) {
                onChangeRef.current({
                    originalEvent: event,
                    value: newValue,
                    stopPropagation: () => {
                        event?.stopPropagation();
                    },
                    preventDefault: () => {
                        event?.preventDefault();
                    },
                    target: {
                        value: newValue
                    }
                });
            }
        }
    }

    const createBackwardNavigator = (isVisible: boolean) => {
        const navigatorProps = isVisible
            ? {
                onClick: onPrevBtnClick,
                onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => onContainerButtonKeydown(e)
            }
            : {};

        return (
            <button type='button' className={`${css({
                width: "2rem",
                height: "2rem",
                color: "#6b7280",
                border: "0 none",
                background: "transparent",
                borderRadius: "50%",
                transition: "background-color 0.2s, color 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                position: "relative",
                visibility: isVisible ? 'visible' : 'hidden',
                h: "auto"
            })} prevbutton`} {...navigatorProps}>
                <ChevronLeftIcon size={24} />
            </button>
        )
    }

    const createForwardNavigator = (isVisible: boolean) => {
        const navigatorProps = isVisible
            ? {
                onClick: onNextBtnClick,
                onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => onContainerButtonKeydown(e)
            } : {}

        return (
            <button type='button' className={`${css({
                width: "2rem",
                height: "2rem",
                color: "#6b7280",
                border: "0 none",
                background: "transparent",
                borderRadius: "50%",
                transition: "background-color 0.2s, color 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                position: "relative",
                visibility: isVisible ? 'visible' : 'hidden',
                h: "auto"
            })} nextbutton`} {...navigatorProps}>
                <ChevronRightIcon size={24} />
            </button>
        )
    }

    const createTitleMonthElement = (month: number) => {
        const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];

        return currentView === 'date' && (
            <button type='button' className={css({
                fontFamily: "Inter",
                marginRight: "0.5rem",
                color: "#4b5563",
                transition: "background-color 0.2s, color 0.2s, box-shadow 0.2s",
                fontWeight: 600,
                padding: "0.5rem",
                textAlign: "left",
                cursor: "pointer",
                fontSize: "0.875rem",
                borderRadius: "6px",
                h: "",
                lineHeight: "normal"
            })} onKeyDown={onContainerButtonKeydown} onClick={switchToMonthView}>
                {monthNames[month]}
            </button>
        )
    }

    const createTitleYearElement = () => {
        const displayYear = currentYear;

        return currentView !== 'year' && (
            <button type='button' className={css({
                fontFamily: "Inter",
                marginRight: "0.5rem",
                color: "#4b5563",
                transition: "background-color 0.2s, color 0.2s, box-shadow 0.2s",
                fontWeight: 600,
                padding: "0.5rem",
                textAlign: "left",
                cursor: "pointer",
                fontSize: "0.875rem",
                borderRadius: "6px",
                h: "",
                lineHeight: "normal"
            })} onClick={switchToYearView}>
                {displayYear}
            </button>
        )
    }

    const createTitleDecadeElement = () => {
        const years = yearPickerValues();

        if (currentView === 'year') {
            return (
                <span>
                    {`${years[0]} - ${years[years.length - 1]}`}
                </span>
            )
        }

        return null;
    }

    const createTitle = (monthMetaData: any) => {
        const month = createTitleMonthElement(monthMetaData.month);
        const year = createTitleYearElement();
        const decade = createTitleDecadeElement();
        return (
            <div className={css({
                lineHeight: "2rem",
                margin: "0 auto"
            })}>
                {month}
                {year}
                {decade}
            </div>
        )
    }

    const createDayNames = (weekDays: any) => {
        const dayNames = weekDays.map((weekDay: any, index: number) => (
            <th className={css({
                padding: "0.5rem",
                fontWeight: 700,
            })} scope='col' key={`${weekDay}-${index}`}>
                <span className={css({
                    width: "2.5rem",
                    height: "2.5rem",
                })}>
                    {weekDay}
                </span>
            </th>
        ));

        return dayNames;
    }

    const createDateCellContent = (date: any, dateClassName: string, index: number) => {
        const content = date.day;

        return (
            <span
                className={dateClassName}
                onClick={(e) => onDateSelect(e, date)}
                onKeyDown={(e) => onDateCellKeydown(e, date, index)}
            >
                {content}
            </span>
        )
    }

    const createWeek = (weekDates: any, index: number) => {
        const week = weekDates.map((date: any) => {
            const selected = isSelected(date);
            const disabled = !date.selectable;
            const dateClassName = (selected ? ' highlight ' : '') + (date.today ? ' today' : '') + (disabled ? ' disabled' : '');
            const content = createDateCellContent(date, dateClassName, index)

            return (
                <td className={css({
                    padding: "0.5rem",
                    borderCollapse: "collapse",
                    fontSize: "0.875rem",
                    lgDown: {
                        padding: "0.4rem",
                        fontSize: "0.8rem",
                    }
                })} key={date.day}>
                    {content}
                </td>
            )
        });

        return week;
    }

    const createDates = (monthMetaData: any, index: number) => {
        return monthMetaData.dates.map((weekDates: any, index: number) => (
            <tr className={css({
                borderCollapse: "collapse",
            })} key={index}>
                {createWeek(weekDates, index)}
            </tr>
        ));
    }

    const createDateViewGrid = (monthMetaData: any, weekDays: any, index: number) => {
        const dayNames = createDayNames(weekDays);
        const dates = createDates(monthMetaData, index);

        return (
            currentView === 'date' && (
                <div>
                    <table role="grid" className={css({
                        width: "100%",
                        borderCollapse: "collapse",
                        fontSize: "0.875rem",
                        margin: "0.5rem 0",
                        lgDown: {
                            fontSize: "0.8rem",
                        }
                    })}>
                        <thead>
                            <tr>{dayNames}</tr>
                        </thead>
                        <tbody className={css({
                            borderCollapse: "collapse",
                            "& span": {
                                width: "2.5rem",
                                height: "2.5rem",
                                borderRadius: "50%",
                                transition: "box-shadow 0.2s",
                                border: "1px solid transparent",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                margin: "0 auto",
                                overflow: "hidden",
                                position: "relative",
                                lgDown: {
                                    width: "2rem",
                                    height: "2rem",
                                }
                            }
                        })}>{dates}</tbody>
                    </table>
                </div>
            )
        )
    }

    const createMonth = (monthMetaData: any, index: number) => {
        const weekDays = createWeekDaysMeta();
        const backwardNavigator = createBackwardNavigator(index === 0);
        const forwardNavigator = createForwardNavigator(true);
        const title = createTitle(monthMetaData);

        const dateViewGrid = createDateViewGrid(monthMetaData, weekDays, index);
        const monthKey = monthMetaData.month + '_' + monthMetaData.year;

        return (
            <div key={monthKey}>
                <div className={css({
                    padding: '0.5rem',
                    color: "#4b5563",
                    margin: 0,
                    borderBottom: "1px solid #e5e7eb",
                    borderTopRightRadius: "6px",
                    borderTopLeftRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                })}>
                    {backwardNavigator}
                    {title}
                    {forwardNavigator}
                </div>
                {dateViewGrid}
            </div>
        );
    }

    const createMonths = (monthsMetaData: any) => {
        const groups = monthsMetaData.map(createMonth);

        return (
            <>
                {groups}
            </>
        );
    }

    const createDateView = () => {
        const viewDate = getViewDate();
        const monthsMetaData = createMonthsMeta(viewDate.getMonth(), viewDate.getFullYear());
        const months = createMonths(monthsMetaData);

        return months;
    }

    const createMonthPicker = () => {
        if (currentView === 'month') {
            return (
                <div className={`${css({
                    margin: "0.5rem 0",
                })} monthpicker`}>
                    {monthPickerValues().map((m, i) => {
                        const selected = isMonthSelected(i)
                        const disabled = isMonthYearDisabled(i, currentYear);

                        return (
                            <span className={`${css({
                                padding: "0.5rem",
                                transition: "box-shadow 0.2s",
                                borderRadius: "6px",
                                width: "33.3%",
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                overflow: "hidden",
                                position: "relative",
                            })} month ${selected ? "highlight" : ""} ${disabled ? "disabled" : ""}`} onClick={(e) => onMonthSelect(e, i)} onKeyDown={(e) => onMonthCellKeydown(e, i)} key={`month${i + 1}`}>
                                {m}
                            </span>
                        )
                    })}
                </div>
            )
        }

        return null;
    }

    const createYearPicker = () => {
        if (currentView === 'year') {
            return (
                <div className={`${css({
                    margin: "0.5rem 0",
                    display: "inline-block"
                })} yearpicker`}>
                    {yearPickerValues().map((y, i) => {
                        const selected = isYearSelected(y);
                        const disabled = isMonthYearDisabled(-1, y);

                        return (
                            <span className={`${css({
                                padding: "0.5rem",
                                transition: "box-shadow 0.2s",
                                borderRadius: "6px",
                                width: "50%",
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                overflow: "hidden",
                                position: "relative",
                            })} year ${selected ? "highlight" : ""} ${disabled ? "disabled" : ""}`} onClick={(e) => onYearSelect(e, y)} onKeyDown={(e) => onYearCellKeydown(e, y)} key={`year${i + 1}`}>
                                {y}
                            </span>
                        );
                    })}
                </div>
            );
        }

        return null;
    }
    // #endregion

    // #region React
    useMountEffect(() => {
        let viewDate = getViewDate(props.viewDate);

        setViewDateState(viewDate);

        setCurrentMonth(viewDate.getMonth());
        setCurrentYear(viewDate.getFullYear());
        setCurrentView(props.view);

        initFocusableCell();

        if (props.value) {
            setValue(props.value);
        }
    })

    useEffect(() => {
        onChangeRef.current = props.onChange;
    }, [props.onChange]);

    useUpdateEffect(() => {
        if (overlayRef.current) {
            setNavigationState(viewDateState);
        }
    });

    useUpdateEffect(() => {
        if (viewChangedWithKeyDown.current) {
            setCurrentView(props.view);
        }

        viewChangedWithKeyDown.current = false;
    }, [props.view]);

    useUpdateEffect(() => {
        focusToFirstCell();
    }, [currentView]);

    useUpdateEffect(() => {
        if (!props.onViewDateChange && !viewStateChanged.current) {
            setValue(props.value);
        }

        if (props.viewDate) {
            updateViewDate(null, getViewDate(props.viewDate));
        }
    }, [props.onViewDateChange, props.value, props.viewDate]);

    useUpdateEffect(() => {
        const newDate = props.value;

        if (previousValue !== newDate) {
            if (!newDate) return;

            let viewDate = newDate;

            if (viewDate instanceof Date) {
                setViewDateState(viewDate);
                setCurrentMonth(viewDate.getMonth());
                setCurrentYear(viewDate.getFullYear());
            }
        }
    }, [props.value]);

    useImperativeHandle(ref, () => ({
        props,
        getCurrentDateTime,
        getViewDate,
        updateViewDate
    }))


    // #endregion

    // #region Components
    const datePicker = createDateView();
    const monthPicker = createMonthPicker();
    const yearPicker = createYearPicker();
    // #endregion

    return (
        <div className={css({
            position: 'relative',
            display: 'inline-flex',
            fontFamily: 'Inter !',
            background: "#fff",
            width: '100%',
            maxWidth: "408px",
            "& .today": {
                background: "#d1d5db",
                color: "#4b5563"
            },
            "& .highlight": {
                color: "#0e7490",
                background: "#ecfeff"
            },
            "& .disabled": {
                opacity: 0.4,
                cursor: "not-allowed",
            }
        })} ref={overlayRef}>
            <div className={css({
                display: 'inline-block',
                position: 'static',
                overflowX: 'auto',
                padding: "0.5rem",
                color: "#4b5563",
                border: "1px solid #d1d5db",
                borderRadius: '6px',
                maxWidth: "408px",
                width: '100%',
            })}>
                {datePicker}
                {monthPicker}
                {yearPicker}
            </div>
        </div>
    );
}));