import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react'
import { css } from '../../../../styled-system/css';
import { FormEvent } from './Calendar';
import { usePrevious } from '../../../hooks/usePrevious';

export const WorkingHours = memo(forwardRef((inProps: any, ref) => {
    const props: {
        data: any[],
        date: Date,
        value: any,
        onChange?: (event: FormEvent<Date>) => void,
    } = { ...inProps }

    const onChangeRef = useRef<((event: FormEvent<Date>) => void) | undefined>(undefined);

    const getTwoNumber = (number: number) => {
        return number.toString().length === 1 ? `0${number}` : `${number}`;
    }

    const isTimeEquals = (value: any, dateMeta: any) => {
        if (value) {
            return value.id === dateMeta.id;
        }

        return false;
    }

    const isSelected = (timeMeta: any) => {
        if (props.value) {
            return isTimeEquals(props.value, timeMeta);
        } else {
            return false;
        }
    }

    const selectTime = (event: React.SyntheticEvent, newValue: any) => {
        if (onChangeRef.current) {
            onChangeRef.current({
                originalEvent: event,
                value: newValue,
                stopPropagation: () => {
                    event?.stopPropagation();
                },
                preventDefault: () => {
                    event?.preventDefault();
                },
                target: {
                    value: newValue
                }
            });
        }
    }

    const onTimeSelect = (event: React.SyntheticEvent, value: any) => {
        if (!event) {
            return;
        }

        selectTime(event, value);
        (event.currentTarget as HTMLElement).focus();

        event.preventDefault();
    }

    const render = () => {

        if (!Array.isArray(props.data)) {
            return <div>No data available</div>;
        }

        return props.data.map((item: any, index: number) => {
            const date = `${getTwoNumber(props.date.getDate())}/${getTwoNumber(props.date.getMonth() + 1)}/${props.date.getFullYear()}`;
            const dateOfEvent = item.start?.date ?? item.allDay;

            if ((date === dateOfEvent)) {
                return (
                    <span className={css({
                        w: "100%",
                        border: "1px solid #d1d5db",
                        padding: "0.5rem",
                        borderRadius: "6px",
                        cursor: "pointer",
                        background: isSelected(item) ? "#ecfeff" : "#fff",
                        color: isSelected(item) ? "#0e7490" : "#4b5563",
                        transition: "all 0.3s",
                        textAlign: "center",
                        "&:hover": {
                            background: "#ecfeff",
                            color: "#0e7490"
                        }
                    })} key={item.start?.timestamp} onClick={(e) => onTimeSelect(e, item)}>
                        {item.start?.timestamp} - {item.end?.timestamp}
                    </span>
                );
            }

            return null;
        });
    }

    useImperativeHandle(ref, () => ({
        props,
    }))

    useEffect(() => {
        onChangeRef.current = props.onChange;
    }, [props.onChange]);

    const rendered = render();

    return (
        <div className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.5rem',
            border: '1px solid #d1d5db',
            borderRadius: '6px',
            overflowY: 'hidden',
            gap: "1rem",
            w: "20rem",
            background: "#fff",
        })}>
            <div className={css({
                padding: "0.5rem",
                color: "#4b5563",
                borderBottom: "1px solid #d1d5db",
                w: "100%",
                textAlign: "center",
            })}>
                {getTwoNumber(props.date.getDate())}/{getTwoNumber(props.date.getMonth() + 1)}/{props.date.getFullYear()}
            </div>
            <div className={css({
                display: 'flex',
                flexDirection: 'column',
                padding: '0.5rem',
                gap: ".5rem",
                w: "100%",
            })}>
                {rendered}
            </div>
        </div>
    );
}));
