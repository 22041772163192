import React, { useEffect, useRef } from 'react';

export const usePrevious = (newValue: any) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current = newValue;

        return () => {
            ref.current = null;
        }
    }, [newValue]);

    return ref.current;
};