import React from 'react';
import { Image } from '../Image';
import { css } from '../../../../styled-system/css';
import { Package } from '@dup/products';
import ResponsiveImage from '../ResponsiveImage';

const AbsoluteImages = ({ pkg }: { pkg: Package }) => {
    return (
        <>
            <ResponsiveImage
                className={css({
                    pos: 'absolute',
                    rounded: '50%',
                    opacity: '.4',
                    width: 'calc(750px * 0.8)',
                    height: 'calc(750px * 0.8)',
                    objectFit: 'cover',
                    right: '0',
                    transform: 'translateX(50%)',
                    top: '18rem',
                    zIndex: '-1',
                })}
                relativePath={`packages/${pkg.id}/thumbnail.png`}
            />
            <ResponsiveImage
                className={css({
                    pos: 'absolute',
                    rounded: '50%',
                    opacity: '.4',
                    width: 'calc(500px * 1.1)',
                    height: 'calc(500px * 1.1)',
                    objectFit: 'cover',
                    left: '0',
                    transform: 'translateX(-50%)',
                    top: '80rem',
                    zIndex: '-1',
                })}
                relativePath={`packages/${pkg.id}/${pkg.images.at(0)?.name}`}
            />
        </>);
}

export default AbsoluteImages;