import React, { useEffect } from 'react'
import { Typography } from '../components/photographie/Typography'
import { Package } from '@dup/products'
import { css } from '../../styled-system/css'
import { Pricing, PricingCard } from '../components/photographie/order/Pricing'
import { useOrderValue, useSetOrder } from '../atoms/order.atom'
import { CARDS_COLORS } from '../consts'
import { Button } from '../components/photographie/Button'
import { scrollToTop } from '../utils/scrollToTop'

export const PackSelection = (props: { pkg: Package; next: () => void }) => {
  const { pkg, next } = props
  const setOrder = useSetOrder()
  const currentOrder = useOrderValue()

  const cards: PricingCard[] = pkg.subs.map((_sub, idx) => {
    const colorIndex = idx % 3

    return {
      id: _sub.id,
      label: _sub.label,
      text: _sub.description,
      footer: {
        value: _sub.discount?.label,
        color: '#BDCCBD',
      },
      price: {
        color: '#D9C58B',
        value: _sub.price,
        label: _sub.discount?.value,
        labelColor: _sub.discount?.color,
      },
      bgColor: CARDS_COLORS[colorIndex],
    }
  })

  const handleChange = (value: string | null) => {
    setOrder((prevValue) => {
      return { ...prevValue, sub: value }
    })
  }

  useEffect(scrollToTop, [])

  return (
    <div className={css({ display: 'flex', flexDir: 'column' })}>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '3.125rem',
          flexWrap: 'wrap',
          flexDir: 'column',
          textAlign: 'center',
          lgDown: {
            gap: 0,
          }
        })}
      >
        <div
          className={css({
            flex: 0.5,
            display: 'flex',
            flexDir: 'column',
            gap: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            lgDown: {
              width: '100%',
            }
          })}
        >
          <Typography className={css({ color: 'fonts.darkBlue' })} as="h1">
            Sélectionnez votre forfait
          </Typography>
          <Typography
            className={css({
              color: 'fonts.greyBlue',
              textAlign: 'center',
              marginLeft: '1.25rem',
              marginRight: '1.25rem',
            })}
            as="p"
          >
            Les tarifs comprennent la prise de vue, les retouches et les frais
            divers photographiques(serveurs hébergement photos, abonnements
            logiciels retouche, fiscalité, abonnements aux sites partenaires
            produits photos et galeries, accessoires, etc.)
          </Typography>
        </div>

        <Pricing onChangeCard={handleChange} cards={cards} />

        <div>
          <Button
            disabled={currentOrder.sub === null}
            onClick={next}
            variants={{ format: 'normal', visual: 'digit' }}
          >
            JE RESERVE CE PACK
          </Button>
        </div>
      </div>
    </div>
  )
}
