import React, { useEffect, useState } from 'react'
import { useSetOrder } from '../atoms/order.atom'
import { Typography } from '../components/photographie/Typography'
import { Package } from '@dup/products'
import { css } from '../../styled-system/css'
import { Button } from '../components/photographie/Button'
import { scrollToTop } from '../utils/scrollToTop'
import { useApi } from '../hooks/useApi'

export const OptionsSelection = (props: {
  pkg: Package
  back: () => void
  next: () => void
}) => {
  const { back, next } = props
  const setOrder = useSetOrder()
  const [lifeStyle, setLifeStyle] = useState<boolean>(false)
  const [address, setAddress] = useState<string>('')
  const [photosSup, setPhotoSup] = useState<number>(0)
  const [distance, setDistance] = useState<number>(0)
  const [btnActive, setBtnActive] = useState<boolean>(true)
  let timeout: NodeJS.Timeout
  const { getDistance } = useApi()

  const handleLifeStyleChange = (value: boolean) => {
    setLifeStyle(value)
    if (!value) {
      setAddress('')
      setDistance(0)
      setBtnActive(true)
    } else {
      setBtnActive(false)
    }

    updateOrder(value, '', 0, undefined)
  }

  const handlePhotosSupChange = (value: number) => {
    let newVal = photosSup + value
    newVal = Math.max(0, newVal)

    setPhotoSup(newVal)
    updateOrder(undefined, undefined, undefined, newVal)
  }

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeout)
    setBtnActive(false)
    timeout = setTimeout(async () => {
      const resp = await getDistance(e.target.value)
      if (resp['distance']) {
        setAddress(e.target.value)
        setDistance(resp['distance'])
        updateOrder(undefined, e.target.value, resp['distance'], undefined)
        setBtnActive(true)
      } else {
        setBtnActive(false)
      }
    }, 500)
  }

  const updateOrder = (
    LifeStyle?: boolean,
    Address?: string,
    Distance?: number,
    PhotosSup?: number
  ) => {
    setOrder((prevValue) => {
      return {
        ...prevValue,
        options: {
          LifeStyle: LifeStyle ?? lifeStyle,
          Address: Address ?? address,
          Distance: Distance ?? distance,
          PhotosSup: PhotosSup ?? photosSup,
        },
      }
    })
  }

  useEffect(scrollToTop, [])

  return (
    <>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '3.125rem',
          flexWrap: 'wrap',
          flexDir: 'column',
          textAlign: 'center',
          lgDown: {
            gap: 0,
          },
        })}
      >
        <div
          className={css({
            flex: 0.5,
            display: 'flex',
            flexDir: 'column',
            gap: '1rem',
            justifyContent: 'center',
            width: '50%',
            lgDown: {
              width: '100%',
            },
          })}
        >
          <Typography
            className={css({ color: 'fonts.darkBlue', textAlign: 'center' })}
            as="h1"
          >
            Sélectionnez vos options
          </Typography>

          <div
            className={css({
              display: 'flex',
              flexWrap: 'no-wrap',
              gap: '1rem',
              justifyContent: 'start',
              alignItems: 'center',
            })}
          >
            <h2
              className={css({
                fontFamily: 'Assistant',
                fontWeight: '500',
                fontSize: '1.5rem',
              })}
            >
              Photos supplémentaires
            </h2>
            <div
              className={css({
                display: 'flex',
                flexWrap: 'no-wrap',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <Button
                onClick={() => handlePhotosSupChange(-1)}
                className={css({
                  aspectRatio: 1,
                  borderWidth: '0.3rem !',
                  lineHeight: '1.55rem',
                  scale: '.45',
                  fontSize: '3rem',
                  _active: {
                    scale: '.6 !',
                  },
                })}
                variants={{ format: 'rounded', visual: 'secondary' }}
              >
                -
              </Button>
              <div
                className={css({
                  width: '4rem',
                  height: '2rem',
                  border: '.15rem solid black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                })}
              >
                {photosSup}
              </div>
              <Button
                onClick={() => handlePhotosSupChange(1)}
                className={css({
                  aspectRatio: 1,
                  borderWidth: '0.3rem !',
                  lineHeight: '1.55rem',
                  scale: '.45',
                  fontSize: '3rem',
                  _active: {
                    scale: '.6 !',
                  },
                })}
                variants={{ format: 'rounded', visual: 'secondary' }}
              >
                +
              </Button>
            </div>
            <p
              className={css({
                fontStyle: 'italic',
                fontSize: '.85rem',
                fontFamily: 'Assistant',
                fontWeight: '300',
                textAlign: 'start',
              })}
            >
              Vous pourrez décider le jour où vous verrez les photos
            </p>
          </div>

          <div
            className={css({
              width: '100%',
              display: 'flex',
              flexDir: 'column',
              gap: '1rem',
              justifyContent: 'start',
              alignItems: 'start',
            })}
          >
            <div
              className={css({
                display: 'flex',
                flexWrap: 'no-wrap',
                alignItems: 'center',
                justifyContent: 'start',
                gap: '1rem',
              })}
            >
              <button
                onClick={() => handleLifeStyleChange(!lifeStyle)}
                className={`${css({
                  width: '1.2rem',
                  height: '1.2rem',
                  border: '.15rem solid #19213D',
                  position: 'relative',
                  transition: 'border-color ease .2s',
                  cursor: 'pointer',
                  '&::before, &::after': {
                    position: 'absolute',
                    height: '0',
                    width: 'calc(1.2rem * 0.125)',
                    backgroundColor: '#19213D',
                    transformOrigin: 'left top',
                    content: "''",
                    transition: 'opacity ease .5s',
                  },
                  '&:before': {
                    top: 'calc(1.2rem * 0.62)',
                    left: 'calc(1.2rem * 0.41)',
                    boxShadow: '0 0 0 calc(1.2rem * .115) #fff',
                    transform: 'rotate(-135deg)',
                  },
                  '&:after': {
                    top: 'calc(1.2rem * 0.24)',
                    left: 'calc(1.2rem * 0.05)',
                    transform: 'rotate(-45deg)',
                  },
                  '&.checked': {
                    '&:after': {
                      height: lifeStyle ? 'calc(1.2rem * 0.5)' : '0',
                    },
                    '&:before': {
                      height: lifeStyle ? 'calc(1.2rem * 1.1)' : '0',
                    },
                  },
                })} ${lifeStyle ? 'checked' : ''}`}
              ></button>

              <h2
                className={css({
                  fontFamily: 'Assistant',
                  fontWeight: '500',
                  fontSize: '1.5rem',
                  textAlign: 'start',
                })}
              >
                Options LifeStyle (Séance photo à votre domicile)
              </h2>
            </div>

            {lifeStyle && (
              <div
                className={css({
                  display: 'flex',
                  flexDir: 'column',
                  paddingLeft: '2.5rem',
                })}
              >
                <h3
                  className={css({
                    fontFamily: 'Assistant',
                    fontWeight: '500',
                    fontSize: '1rem',
                    textAlign: 'start',
                  })}
                >
                  Veuillez saisir l&apos;adresse de votre domicile :
                </h3>
                <input
                  className={css({
                    border: '.15rem solid #19213D',
                    padding: '0.5rem 1rem',
                    borderRadius: '.2rem',
                  })}
                  type="text"
                  onChange={(e) => handleAddressChange(e)}
                />
                <p
                  className={css({
                    fontFamily: 'Assistant',
                    fontWeight: '400',
                    fontStyle: 'italic',
                    fontSize: '1rem',
                    textAlign: 'start',
                  })}
                >
                  Vous habitez à{' '}
                  <span
                    className={css({
                      fontWeight: '600',
                    })}
                  >
                    {distance}km
                  </span>{' '}
                  du studio
                </p>
              </div>
            )}
          </div>

          <div
            className={css({
              alignSelf: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '70%',
              mt: '20px',
            })}
          >
            <Button
              onClick={back}
              variants={{ format: 'normal', visual: 'secondary' }}
            >
              Retour
            </Button>
            <Button
              onClick={next}
              variants={{ format: 'normal', visual: 'digit' }}
              disabled={!btnActive}
            >
              JE VALIDE
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
